import React, { useContext } from 'react';
import styled from 'styled-components';
import { color } from '../theme/color';
import { useOnHover } from '../hooks/useOnHover';
import { useTranslation } from '../i18n/useTranslation';
import { grid } from '../theme/theme';
import { extractWithLocale } from '../utils/extractWithLocale';
import { getLocalizedLink } from '../utils/getLocalizedLink';
import { NavPage, Sections } from '../__generated__/graphql';
import { Blob } from './blobs/OrangeBlob';
import { Box } from './common/Box';
import { Grid } from './common/Grid';
import { NavLink } from './common/NavLink';
import { Text } from './common/Text';
import { useLocale } from './CurrentLocale';
import { NavigationLinksContext } from './Layout';
import { SectionRenderer } from './sections/SectionRenderer';

interface BottomSectionProps {
  pageId: string;
  sections: Sections[];
  bg: string;
}

export const BottomSection: React.FunctionComponent<BottomSectionProps> = ({
  pageId,
  sections,
  bg,
}) => {
  const navLinks = useContext(NavigationLinksContext).slice() as [NavPage | { id: string }];
  navLinks.unshift({ id: 'startPage' });

  const locale = useLocale();
  const t = useTranslation();

  const [hover, hoverProps] = useOnHover();

  const currentPage = navLinks?.find((page: any) => page.id === pageId);

  const nextPage = currentPage && (navLinks[navLinks?.indexOf(currentPage) + 1] as NavPage);

  if (!nextPage && sections.length <= 0) return null;

  return (
    <Box mt={[0, -6]} gridColumn="1 / -1">
      <Grid mt={[0, 0, 0, 5]} gridTemplateColumns={grid}>
        <SectionRenderer sections={sections} />
        {nextPage && (
          <Box
            gridColumn="1 / -1"
            display="flex"
            alignItems="center"
            justifyContent="center"
            pt={6}
            pb={7}
          >
            <NavLink
              underline="large"
              color={hover ? bg : bg === color.white ? bg : '#000'}
              to={getLocalizedLink(nextPage, locale)}
              {...hoverProps}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Text
                  fontSize={2}
                  fontWeight={500}
                  textTransform="uppercase"
                  letterSpacing="0.2em"
                  fontFamily="Rubik"
                  color={hover ? bg : bg === color.white ? bg : '#000'}
                >
                  {t('next')}
                </Text>
                <Text
                  fontFamily="Quarto A, Quarto, Quarto B"
                  fontSize={[7, 8, 9, 10, 11]}
                  fontWeight={900}
                  color={hover ? bg : bg === color.white ? bg : '#000'}
                >
                  {nextPage.__typename === 'SanityPage'
                    ? extractWithLocale(nextPage.name, locale) ?? ''
                    : extractWithLocale(nextPage.title, locale) ?? ''}
                </Text>
              </Box>
            </NavLink>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

const BlobWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
