import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { BottomSection } from '../components/BottomSection';
import { Grid } from '../components/common/Grid';
import { Layout } from '../components/Layout';
import { SectionRenderer } from '../components/sections/SectionRenderer';
import { Seo } from '../components/Seo';
import { DefaultTopSection } from '../components/top/DefaultTopSection';
import { StartTopSection } from '../components/top/StartTopSection';
import { TalksTopSection } from '../components/top/TalksTopSection';
import { VideoSection } from '../components/top/VideoSection';
import { grid } from '../theme/theme';
import { extractWithLocale } from '../utils/extractWithLocale';
import { getBottomColors } from '../utils/getBottomColors';
import { getSlugsFromPage } from '../utils/getSlugsFromPage';
import { isNotNull } from '../utils/isNotNull';
import { PageProps } from '../utils/PageProps';
import { combineFullSections } from '../utils/rawSectionHelpers';
import { PageQuery } from '../__generated__/graphql';
import { isIE } from 'react-device-detect';
import BrowserNotSupported from '../components/BrowserNotSupported';
import { TalksProvider } from '../components/TalksProvider';
import { useThemeContext } from '../components/ThemeChanger';

const Page: React.FC<PageProps<PageQuery>> = ({ data, pageContext, location }) => {
  const [notSupportedBrowser, setNotSupportedBrowser] = useState(false);
  const themeCtx = useThemeContext();
  useEffect(() => {
    setNotSupportedBrowser(isIE);

    const isPlayPage = data.page! && data.page.pageType === 'playPage';
    if (isPlayPage) {
      themeCtx.setTheme('dark');
    } else {
      themeCtx.setTheme('light');
    }
  }, []);
  const page = data.page!;

  if (!page) return null;

  const name = extractWithLocale(page.name, pageContext.locale) ?? '';
  const heading = extractWithLocale(page.heading, pageContext.locale) ?? '';
  const preamble = extractWithLocale(page.preamble, pageContext.locale) ?? '';
  const videoId = page.video ?? '';

  const image = page.image?.asset?.gatsbyImageData;

  const top = page.topSectionType;

  const slugs = getSlugsFromPage(page);
  const sections = page.sections?.filter(isNotNull) ?? [];
  const rawSections = page._rawSections?.filter(isNotNull) ?? [];
  const fullSections = combineFullSections(sections, rawSections);

  const seoTitle = page.seo && extractWithLocale(page.seo.title, pageContext.locale);
  const seoDescription = page.seo && extractWithLocale(page.seo.description, pageContext.locale);
  const seoImage = page.seo?.seoImage?.asset?.gatsbyImageData?.images?.fallback?.src;

  const pageId = page.pageType === 'startPage' ? 'startPage' : page.id;

  const bottomSectionBgColor = getBottomColors(page.name);
  const bottomSections = page.bottomSections?.filter(isNotNull) ?? [];
  const rawBottomSections = page._rawBottomSections?.filter(isNotNull) ?? [];
  const fullBottomSections = combineFullSections(bottomSections, rawBottomSections);

  const TopSection =
    top === 'startPage'
      ? StartTopSection
      : top === 'video'
      ? VideoSection
      : page.pageType === 'talksPage'
      ? TalksTopSection
      : DefaultTopSection;

  const isStuffPage = page.pageType === 'articlePage';
  const isPlayPage = page.pageType === 'playPage';

  return notSupportedBrowser ? (
    <Layout
      locale={pageContext.locale}
      alternativeLinks={slugs}
      overlayHeaderOverContent={top === 'video'}
      location={location}
      showFooter={false}
    >
      <Seo
        title={seoTitle || name || heading}
        description={seoDescription || preamble || ''}
        clearTemplate={page.seo?.clearTemplate}
        image={seoImage ? { url: seoImage, width: 1200, height: 630 } : null}
        location={location}
      />
      <Grid gridTemplateColumns={grid} data-component="wut">
        <BrowserNotSupported locale={pageContext.locale} />
      </Grid>
    </Layout>
  ) : (
    <TalksProvider show={page.showEsattoTalksBubble}>
      <Layout
        locale={pageContext.locale}
        alternativeLinks={slugs}
        overlayHeaderOverContent={top === 'video'}
        location={location}
      >
        <Seo
          title={seoTitle || name || heading}
          description={seoDescription || preamble || ''}
          clearTemplate={page.seo?.clearTemplate}
          image={seoImage ? { url: seoImage, width: 1200, height: 630 } : null}
          location={location}
        />
        <Grid gridTemplateColumns={grid} data-component="wut">
          <TopSection
            heading={heading}
            preamble={preamble}
            videoId={videoId}
            fluidImage={image}
            allowSmaller={isStuffPage || isPlayPage}
          />
          <SectionRenderer sections={fullSections} />
          <BottomSection pageId={pageId} sections={fullBottomSections} bg={bottomSectionBgColor} />
        </Grid>
      </Layout>
    </TalksProvider>
  );
};

export const query = graphql`
  query PageQuery($id: String!) {
    page: sanityPage(_id: { eq: $id }) {
      id
      name {
        sv
        en
      }
      image {
        asset {
          gatsbyImageData
        }
      }
      imageAltText {
        sv
        en
      }
      pageType
      topSectionType
      heading {
        sv
        en
      }
      slug {
        en {
          current
        }
        sv {
          current
        }
      }
      preamble {
        en
        sv
      }
      showEsattoTalksBubble
      video
      _rawSections
      sections {
        ...Sections
      }
      _rawBottomSections
      bottomSections {
        ...Sections
      }

      seo {
        title {
          sv
          en
        }
        description {
          sv
          en
        }
        clearTemplate
        follow
        index
        seoImage: image {
          asset {
            gatsbyImageData(width: 1200, height: 630, layout: FIXED)
          }
        }
      }
    }
  }
`;

export default Page;
