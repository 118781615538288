import { useCallback, useMemo, useState } from 'react';

export const useOnHover = () => {
  const [hover, setHover] = useState(false);
  const onMouseOver = useCallback(() => setHover(true), [setHover]);
  const onMouseLeave = useCallback(() => setHover(false), [setHover]);

  const props = useMemo(() => {
    return {
      onMouseOver,
      onMouseLeave,
    };
  }, [onMouseOver, onMouseLeave]);

  return [hover, props];
};
