import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { bodyPadding } from '../../theme/theme';
import { PosterImageQuery } from '../../__generated__/graphql';
import { Box } from '../common/Box';
import { H1 } from '../common/Heading';
import { Text } from '../common/Text';
import video from '../../images/videos/esatto-video.mp4';

export type VideoSectionProps = {
  heading: string | null;
  preamble: string;
  videoId: string;
};

export const VideoSection: React.FC<VideoSectionProps> = ({ heading, preamble, videoId }) => {
  const { colors } = useContext(ThemeContext);

  const image = useStaticQuery<PosterImageQuery>(posterImageQuery);

  const poster = image.file?.childImageSharp?.gatsbyImageData || null;

  return (
    <Box px={[0, 2, 3, 4, 5]} pb={[0, 2, 3, 4, 5]} gridColumn="1 / -1" width="100%" height="100vh">
      <Box position="relative" height="100%" width="100%" mt="-120px">
        <Box position="absolute" width="100%" height="100%" overflow="hidden">
          <Box
            position="absolute"
            top="0"
            left="0"
            height="100%"
            width="100%"
            zIndex={1}
            style={{
              background:
                'linear-gradient(15deg, rgba(0,0,0,0.2752451322325805) 0%, rgba(255,255,255,0) 50%)',
              objectFit: 'cover',
            }}
          ></Box>
          {poster && (
            <GatsbyImage
              image={poster}
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
              }}
              imgStyle={{ objectFit: 'cover' }}
              alt="video poster"
            />
          )}
          <video
            loop={true}
            autoPlay={true}
            muted={true}
            playsInline={true}
            style={{ position: 'absolute', height: '100%', width: '100%', objectFit: 'cover' }}
          >
            <source src={video} type="video/mp4" />
          </video>
          <Box
            position="absolute"
            pl={[3, 3, 4, 5]}
            maxWidth={650}
            zIndex={2}
            left={0}
            bottom={['20%', '20%', '20%', 5]}
          >
            <H1 color={colors.bg} mb={0}>
              {heading}
            </H1>
            <Text as="p" fontSize={[2, 3]} pr={bodyPadding} color={colors.bg} mb={0}>
              {preamble}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const posterImageQuery = graphql`
  query PosterImageQuery {
    file(relativePath: { eq: "videos/esatto-video-cover.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
`;
