import React from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { bodyPadding } from '../../theme/theme';
import { FluidImage } from '../../__generated__/graphql';
import { Box } from '../common/Box';
import { H1 } from '../common/Heading';
import { InnerGrid } from '../common/InnerGrid';
import { Text } from '../common/Text';

export type DefaultTopSectionProps = {
  heading: string | null;
  fluidImage: FluidImage | null | undefined;
  preamble: string | null;
  allowSmaller: boolean | null;
};

export const DefaultTopSection: React.FC<DefaultTopSectionProps> = ({
  heading,
  preamble,
  allowSmaller,
}) => {
  const isMobile = useIsMobile();

  const marginTop = isMobile && allowSmaller ? 1 : [1, 5];
  const pb = allowSmaller ? [4, 5] : [4, 6];

  return (
    <InnerGrid mt={marginTop} px={bodyPadding} pb={pb}>
      <Box gridColumn={['1 / -1', '1 / -1', '1 / -1', '1 / -1', '1 / 3']} pr={[0, 4]}>
        <H1 fontSize={[5, 6, 6, 7, 7, 8]} mb={0}>
          {heading}
        </H1>
      </Box>
      <Box gridColumn={['1 / -1', '1 / -1', '1 / -1', '1 / -1', '3 / 5']}>
        <Text fontSize={[2, 3]} fontWeight={300} pt={[0, 0, 0, 0, '4px']}>
          {preamble}
        </Text>
      </Box>
    </InnerGrid>
  );
};
