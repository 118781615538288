import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useWindowSize } from '../../hooks/useWindowSize';
import { bodyPadding } from '../../theme/theme';
import { Box } from '../common/Box';
import { H1 } from '../common/Heading';
import { Text } from '../common/Text';

export type StartTopSectionProps = {
  heading: string | null;
  fluidImage: IGatsbyImageData | null | undefined;
  preamble: string;
};

export const StartTopSection: React.FC<StartTopSectionProps> = ({
  heading,
  preamble,
  fluidImage,
}) => {
  const { colors } = useContext(ThemeContext);
  const { height, width } = useWindowSize();

  let aspectRatio = width / (height - 75);

  if (aspectRatio < 16 / 10) aspectRatio = 16 / 10;

  return (
    <>
      <Box px={[0, 2, 3, 4, 5]} gridColumn="1 / -1" position="relative">
        <ImageWrapper>
          <GatsbyImage image={{ ...(fluidImage as any), aspectRatio }} style={{ height: '100%' }} />
        </ImageWrapper>
        <Box position={'absolute'} bottom={['20px', '10%']} left={0} right={0} zIndex={2}>
          <Box position="relative" pl={[3, 3, 4, 5, 6]} maxWidth={650}>
            <H1 color={colors.text} mb={0}>
              {heading}
            </H1>
            <Text as="p" fontSize={[2, 3]} pr={bodyPadding}>
              {preamble}
            </Text>
          </Box>
        </Box>
      </Box>
      {/* <InnerGrid px={bodyPadding}>
      <Box gridColumn={['1 / -1', '1 / 3']} position="relative">
        <Box position={['relative', 'absolute']} top={[0, '20vh']} left={0} right={0} zIndex={2}>
          <Box position="relative" left={[0, 0, 0, -30]} mr={[0, 0, 0, -30]}>
            <H1 color={colors.text}>{heading}</H1>
          </Box>
        </Box>
      </Box>
    </InnerGrid>
    <InnerGrid pl={bodyPadding}>
      <Box gridColumn={['1 / -1', '2 / -1']} zIndex={1}>
        <Grid gridAutoFlow="row">
          <Box order={[1, -1]}>
            <Img image={fluidImage as any} />
          </Box>
          <Text as="p" pr={bodyPadding}>
            {preamble}
          </Text>
        </Grid>
      </Box>
    </InnerGrid> */}
    </>
  );
};

const ImageWrapper = styled(Box)`
  height: 100vh;
  margin-top: -120px;

  @media screen and (min-width: 40em) {
    padding-bottom: 8px;
  }

  @media screen and (min-width: 52em) {
    padding-bottom: 16px;
  }

  @media screen and (min-width: 64em) {
    padding-bottom: 32px;
  }

  @media screen and (min-width: 80em) {
    padding-bottom: 64px;
  }
`;
